import React from 'react'
import * as styles from './PageNotFound.module.css'
import Layout from '../Layout'

const PageNotFound = ({ CDN_ROOT }) => {
  return (
    <Layout CDN_ROOT={CDN_ROOT}>
      <div className={styles.notFound}>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist...</p>
      </div>
    </Layout>
  )
}

export default PageNotFound
